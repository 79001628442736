import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Hls from "../../../../../node_modules/hls.js/dist/hls";
import styles from "./Video.scss";
import * as EventTypes from "constants/AnalyticsEventTypes";
import { sendVideoEvent } from "utils/Analytics";
import forEach from "lodash/forEach";
import mux from "mux-embed";
import Button from "./Button";

const MUX_ENV = window.APP_ENV?.MUX_ENVIRONMENT_KEY;

export default function Video(props) {
    const [started, setStarted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedTimeCode, setSelectedTimeCode] = useState(null);
    const videoRef = useRef();
    const timerRef = useRef(null);

    const src = `https://stream.mux.com/${props.muxPlaybackId}.m3u8`;
    let poster = `https://image.mux.com/${props.muxPlaybackId}/thumbnail.jpg`;

    if (props.gifPoster) {
        poster = `https://image.mux.com/${props.muxPlaybackId}/animated.gif?width=640&fps=5`;
    } else if (props.thumbnailTimecode) {
        poster = `${poster}?time=${props.thumbnailTimecode}`;
    }

    useEffect(() => {
        if (videoRef.current) {
            const initTime = Date.now();
            mux.monitor(videoRef.current, {
                debug: false,
                data: {
                    env_key: MUX_ENV, // required
                    player_name: 'Video Component', // any arbitrary string you want to use to identify this player
                    player_init_time: initTime,
                }
            });
        }
    }, [videoRef]);

    useEffect(() => {
        if (props.autoPlay) {
            handleStart();
        }

        if (props.onComplete) {
            if (videoRef.current) {
                videoRef.current.addEventListener("ended", () => {
                    props.onComplete();
                });
            }
        }
    }, []);

    const getProgressMap = (duration) => {
        if (duration > 599) {
            return [95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5];
        } else if (duration > 299) {
            return [90, 80, 75, 70, 60, 50, 40, 30, 25, 20, 10];
        } else if (duration > 59) {
            return [85, 75, 50, 25, 10];
        } else if (duration > 29) {
            return [75, 50, 25];
        }
        return [50];
    };

    const handlePlayEvent = (evt) => {
        if (videoRef.current.currentTime > 0) {
            return;
        }
        handleVideoEvent(EventTypes.VIDEO_START);
    };

    let lastPercentage = 0;
    const handleProgressEvent = (evt) => {
        const duration = !!videoRef.current?.duration ? videoRef.current.duration : 0;
        let videoPercent = !!videoRef.current?.duration
            ? Math.round((videoRef.current.currentTime / duration) * 100)
            : 0;
        let shouldSend = false;
        const progressMap = getProgressMap(duration);
        forEach(progressMap, (percentage) => {
            if (!shouldSend && videoPercent === percentage && percentage > lastPercentage) {
                videoPercent = percentage;
                lastPercentage = percentage;
                shouldSend = true;
            }
        });

        if (shouldSend) {
            handleVideoEvent(EventTypes.VIDEO_PROGRESS, videoPercent);
        }
    };

    const handleEndedEvent = (evt) => {
        handleVideoEvent(EventTypes.VIDEO_COMPLETE);
    };

    const handleVideoEvent = (eventType, percentage = null) => {
        if (videoRef.current) {
            const duration = !!videoRef.current?.duration ? videoRef.current.duration : 0;
            let percent = percentage;
            if (!percentage) {
                percent = !!videoRef.current?.duration
                    ? Math.round((videoRef.current.currentTime / duration) * 100)
                    : 0;
            }

            sendVideoEvent(eventType, {
                video_current_time: videoRef.current?.currentTime,
                video_duration: videoRef.current?.duration,
                video_percent: percentage || percent,
                video_provider: "mux",
                video_title: props.analyticsData?.title,
                video_url: src,
                visible: true,
            });
        }
    };

    const handleStart = () => {
        setStarted(true);
        if (videoRef.current) {
            videoRef.current.addEventListener("play", handlePlayEvent);
            videoRef.current.addEventListener("progress", handleProgressEvent);
            videoRef.current.addEventListener("ended", handleEndedEvent);
        }
        if (videoRef.current && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = src;
            videoRef.current.play();
        } else if (videoRef.current && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(videoRef.current);
            videoRef.current.play();
        }

        if (props.isPremiumVideo) {
            timerRef.current = setTimeout(async () => {
                if (videoRef.current) {
                    videoRef.current.pause();
                    if (props.onPremiumVideoPause) {
                        props.onPremiumVideoPause();

                        if (document.pictureInPictureElement) await document.exitPictureInPicture();
                    }
                }
            }, 13000);
        }
    };

    const handleGetTimeCode = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            const newTime = Math.floor(videoRef.current.currentTime);
            setSelectedTimeCode(newTime);
            props.onGetTimecode(newTime);
        }
    };

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.root}>
            <video ref={videoRef} controls className={styles.video} poster={poster} />
            {!started && (
                <div
                    className={styles.poster}
                    onClick={() => (!!props.onClick ? props.onClick() : handleStart())}
                >
                    <img src={poster} />
                    <i className="fas fa-play fa-3x" />
                </div>
            )}
            {props.onGetTimecode && (
                <div className={styles.timeCode}>
                    <Button onClick={handleGetTimeCode} buttonText="Select Current Frame" />
                    {!!selectedTimeCode && <i className="fas fa-check-circle" />}
                </div>
            )}
        </div>
    );
}

Video.propTypes = {
    muxPlaybackId: PropTypes.string.isRequired,
    thumbnailTimecode: PropTypes.number,
    autoPlay: PropTypes.bool,
    onComplete: PropTypes.func,
    analyticsData: PropTypes.shape({
        title: PropTypes.String,
    }),
    onClick: PropTypes.func,
    gifPoster: PropTypes.bool,
    showTimeCode: PropTypes.bool,
    onGetTimeCode: PropTypes.func,
    isPremiumVideo: PropTypes.bool,
    onPremiumVideoPause: PropTypes.func,
};
