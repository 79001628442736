exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Video__root{width:100%;position:relative}.Video__root .Video__video{width:100%}.Video__root .Video__poster{position:absolute;top:0;left:0;width:100%}.Video__root .Video__poster img{width:100%}.Video__root .Video__poster i{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);color:#2380db}.Video__root .Video__poster i:hover{color:#1c66af}.Video__root .Video__timeCode{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.Video__root .Video__timeCode i{color:#099340;font-size:1.75em;margin-left:.25em}", "", {"version":3,"sources":["/tmp/build_30fd48a6/web/js/components/shared/elements/Video.scss"],"names":[],"mappings":"AAAA,aAAM,WAAW,iBAAiB,CAAC,2BAAa,UAAU,CAAC,4BAAc,kBAAkB,MAAM,OAAO,UAAU,CAAC,gCAAkB,UAAU,CAAC,8BAAgB,kBAAkB,QAAQ,SAAS,+BAAgC,aAAa,CAAC,oCAAsB,aAAa,CAAC,8BAAgB,oBAAoB,aAAa,uBAAuB,mBAAmB,sBAAsB,kBAAkB,CAAC,gCAAkB,cAAc,iBAAiB,iBAAkB,CAAC","file":"Video.scss","sourcesContent":[".root{width:100%;position:relative}.root .video{width:100%}.root .poster{position:absolute;top:0;left:0;width:100%}.root .poster img{width:100%}.root .poster i{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);color:#2380db}.root .poster i:hover{color:#1c66af}.root .timeCode{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.root .timeCode i{color:#099340;font-size:1.75em;margin-left:0.25em}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "Video__root",
	"video": "Video__video",
	"poster": "Video__poster",
	"timeCode": "Video__timeCode"
};